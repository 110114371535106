import React, { useEffect, useState } from 'react'
import { IMaterialFilterData } from './GenericSerachDialog.template';
import { createUseStyles } from 'react-jss';
import { FormControl, FormControlLabel, Checkbox, Typography, TablePagination, IconButton, Input, useTheme } from '@mui/material';
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { IVendorByFilter, IVendorFilterRequest } from './VendorSearch.template';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { initialPaginationValues } from '../../../utils/constant';
import { useGenericSearchService } from '../../../services/useGenericSearchService';
import { useSearchService } from '../../../services/useSearchService';
import { ChipItem } from '../../molecules/Chips/ChipsV2';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChipsV3 from '../../molecules/Chips/ChipsV3';
import { enumToString, showAttributesUom } from '../../../utils/helper';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';

interface VendorFilterSelectionTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    chipContainer: {
        width: "100%"
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary900,
    },
    subHeading: {
        color: theme.palette.textV2.primary.primary870,
    },
    values: {
        color: theme.palette.text.secondaryDark,
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.pagination.text.secondaryLight,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
    statusActive: {
        color: theme.palette.textV2.success.success500
    },
    statusInactive: {
        color: theme.palette.textV2.failure.failure500
    },
    statusActiveContainer: {
        borderRadius: "24px",
        backgroundColor: theme.palette.textV2.success.success50
    },
    statusInactiveContainer: {
        borderRadius: "24px",
        backgroundColor: theme.palette.textV2.failure.failure50
    },
    attributeName: {
        color: theme.palette.text.primaryLight
    },
    attributeValue: {
        color: theme.palette.textV2.secondary.secondary600
    },
    border: {
        borderBottom: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
    },
    checkboxText: {
        "&.MuiCheckbox-root.Mui-checked": {
            color: theme.palette.textV2.primary.primary500,
        },
    },
    inputBar: {
        width: "54px",
        height: "28px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.tritiaryLight}`,
        paddingLeft: "16px"
    }

}));

const VendorFilterSelectionTemplate: React.FC<VendorFilterSelectionTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose }) => {
    const classes = useStyles();
    const [vendors, setVendors] = useState<IVendorByFilter[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const searchService = useSearchService();
    const [selectedVendors, setSelectedVendors] = useState<IMultiSelectOption[]>();

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const fetchVendor = async () => {
        console.log('sdfasdfasdfasf')
        let params: IVendorFilterRequest = {
            page: pagination.currentPage,
            size: pagination.pageSize
        }
        const payload = {
            location: materialFilters.vendorLocation?.map(el => el.value) ?? [],
            vendorType: materialFilters.vendorType?.map(el => el.value) ?? [],
            name: null
        }
    
        try {
            const res = await searchService.getVendorsByFilter(params, payload);
            if (
                res?.status === HTTP_STATUS.OK
            ) {
                const { totalElements, totalPages } = res?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                setVendors(res.data.data.content);
            } else {
                throw new Error("Vendor fetch failed");
            }
        } catch (error) {
            // showSnackbar('error', "Vendor fetch failed")
        }
    }

    useEffect(() => {
        fetchVendor();
    }, [materialFilters?.vendorLocation, materialFilters?.vendorType, pagination.currentPage, pagination.pageSize]);

    const updateSelected = (option: IMultiSelectOption) => {
        if(!((materialFilters.vendors?.filter(el => el.value === option.value)?.length ?? 0) > 0)){
            setSelectedVendors([...selectedVendors ?? [], option])
            setFilterData({
                ...materialFilters,
                vendors: [...materialFilters.vendors ?? [], option]
            })
        } else {
            setSelectedVendors(selectedVendors?.filter(el => el.value != option.value))
            setFilterData({
                ...materialFilters,
                vendors: [...materialFilters.vendors?.filter(el => el.value != option.value) ?? []]
            })
        }
    };

    const handleFilterChipDelete = (value: ChipItem, key: string, subKey: string) => {
        const existingFilter = materialFilters?.[key] as IMultiSelectOption[];
        setFilterData({
            ...materialFilters,
            [key]: Array.isArray(existingFilter)
                ? existingFilter.filter((filterItem: any) => filterItem.label !== value.label) ?? [] as IMultiSelectOption[]
                : []
        });
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));

        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            className={classes.inputBar}                        />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    const renderChips = (data: any) => {
        return Object.entries(data).map(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    return null;
                }
                if (key === 'productIds') {
                    return <></>;
                }
                return (
                    <div key={key} className='flex gap-x-2'>
                        <ChipsV3 values={value} onDelete={(value) => handleFilterChipDelete(value, key, '')} />
                    </div>
                );
            }
            if (typeof value === 'object' && value !== null) {
                return (
                    <div key={key} className='flex gap-x-2'>
                        {Object.entries(value).map(([subKey, subValue]) => (
                            <div key={subKey}>
                                {Array.isArray(subValue.values) && subValue.values.length > 0 && (
                                    <>
                                        <ChipsV3 values={subValue.values.map((option: any) => ({ 'label': option, 'id': option }))} onDelete={(value) => handleFilterChipDelete(value, key, subKey)} />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                );
            }

            return null;
        });
    };

    const checkHandler = (id: number): boolean => {
        return (materialFilters?.vendors?.filter(el => el.value == id)?.length ?? 0) > 0
    }

    return (
        <div>
            <div className="flex flex-col">
                <div className="flex justify-between">
                    <div className={`${classes.heading} text-2xl font-medium my-auto`}>Select Vendors</div>
                    <div className={`cursor-pointer`} onClick={handleClose}>
                        <CloseIcon />
                    </div>
                </div>
                <div className={`${classes.subHeading} text-sm `}>
                    {pagination.totalRecords} Results Available
                </div>
            </div>
            <div className={`${classes.border} mt-10`}></div>
            <div className={classes.chipContainer}>
            <div className="flex gap-3 mt-4 flex-wrap">{renderChips(materialFilters)}</div>
            </div>
            <div>
                {vendors?.map((vendor, index) => (
                    <div key={index} className="">
                        <div className={`${classes.container} rounded-lg p-4 mt-4`}>
                            <div className={`flex gap-x-3 items-center`}>
                                <Checkbox
                                    className={`${classes.checkboxText}`}
                                    checked={checkHandler(vendor.id)}
                                    onChange={() => updateSelected({value: vendor.id, label: vendor.name})}
                                />
                                <div className='flex w-full items-center'>
                                    <div className='flex flex-grow justify-between'>
                                        <div className={`${classes.heading} text-base font-medium`}>
                                            {vendor.name}
                                        </div>
                                            <div className={`${classes.subHeading} text-sm`}>
                                                {vendor.location}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <TablePagination
                className={`${classes.paginationTable} w-full mt-4 flex`}
                component="div"
                rowsPerPageOptions={[5, 10, 15]}
                count={pagination.totalRecords}
                page={pagination.currentPage}
                onPageChange={handlePaginationChange}
                rowsPerPage={pagination.pageSize}
                ActionsComponent={TablePaginationActions}
                onRowsPerPageChange={handleRowsPerPageChange}
            />

        </div>
    );
}

export default VendorFilterSelectionTemplate