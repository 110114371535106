import React from 'react';
import emptyState from '../../../App/Assets/Images/emptyState.svg';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button';

const useStyles = createUseStyles((theme: any) => ({
    background: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100,
    },
    textField: {
       color: theme.palette.textV2.secondary.secondary800
    }
}));

interface EmptyStateCardProps {
    buttonText?: string;
    onButtonClick?: () => void;
    message: string;
}

const EmptyStateCard: React.FC<EmptyStateCardProps> = ({ buttonText, onButtonClick, message }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.background} rounded-2xl`}>
            <div className="flex flex-col items-center justify-center text-center">
                <img src={emptyState} alt="Empty State" className="mt-16" />
                <div className="mb-16">
                <div className={`${classes.textField} font-medium text-lg my-6`}> {message}</div>
                    {onButtonClick && (
                        <Button
                            variant="contained"
                            label={buttonText || 'Click Here'}
                            onClick={onButtonClick}
                            className="!font-normal"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmptyStateCard;
