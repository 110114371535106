import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormikErrors } from 'formik';
import deleteIcon from '../../../../App/Assets/Icons/deleteIcon.svg'
import { useNavigate } from 'react-router-dom';
import { LocationSectionProps } from './VendorLocation.template';
import { useGstinService } from '../../../../services/useGstinService';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import { LOCATION_STATES } from '../../../../utils/types';
import { IGstnState } from '../../../pages/VendorManagement/VendorLocation.page';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import GntnDetailModalTemplate from '../../Dashboard/GstnDetailModalTemplate.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    button: {
        color: theme.palette.border._secondaryDark
    },
    text:{
        color: theme.palette.textV2.primary.primary500
    }
    
}));

const AddNewVendorLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const gstinService = useGstinService();
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const [isFormValidate, setIsFormValidate]= useState<boolean>(true);
    const [currentGstin, setCurrentGstin] = useState<string>('');
    const navigate = useNavigate();

    const handleBackToDashboard = ()=> {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
     };
    const validateGstin = (gstin: string) => (index: number) => async () => {
        const gstinInput = gstin;
    
        if (gstin.length === 0) {
            formik.setFieldValue(`newLocations.${index}.gstin`, gstinInput, false);
            formik.setFieldValue(`newLocations.${index}.state`, '', false);
            formik.setFieldTouched(`newLocations.${index}.gstin`, false, false);
            return;
        }
    
        let error = '';
        let state = '';

        if(gstin.length >0 && gstin.length < 15){ error = 'This GSTIN is not valid'}
        
        if (gstin.length === 15) {
            error = '';
            const isDuplicateGstExistsInLocations = formik.values.newLocations.some(
                (location: any, idx: number) => location.gstin?.toLowerCase() === gstinInput?.toLowerCase() && idx !== index
            );
            const isDuplicateGstExistsInExistingLocations = formik.values.locations?.some(
                (location) => location.gstin?.toLowerCase() === gstinInput?.toLowerCase()
            );
    
            if (isDuplicateGstExistsInExistingLocations || isDuplicateGstExistsInLocations) {
                error = 'duplicate gst number';
            } else {
                const response = await gstinService.searchGstin({ gstin });
                if (response?.data?.data?.valid) {
                    state = response.data.data.state?.join(', ');
                } else {
                    error = response?.data?.data?.message || 'This GSTIN is not valid';
                }
            }
        }
        if (gstin.length > 15) {error = 'This GSTIN is not valid'}
        formik.setFieldValue(`newLocations.${index}.gstin`, gstinInput, false);
        formik.setFieldValue(`newLocations.${index}.state`, state, false);
        formik.setFieldError(`newLocations.${index}.gstin`, error);
        formik.setFieldTouched(`newLocations.${index}.gstin`, true, false);
    };
    const onSubmit = () => {
        if (isFormValid()) {
            formik.handleSubmit();
            setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS);
        }
    };
    const isFormValid = () => {
        const { newLocations } = formik.values;
        const {errors} = formik;
        if (!Array.isArray(newLocations) || newLocations.length === 0) {
            return false;
        }
        return newLocations?.every((location, index) => {
            const hasValidGstin = Boolean(location.gstin);
            const hasValidState = Boolean(location.state);
            const locationErrors = (errors?.newLocations as FormikErrors<IGstnState>[] | undefined)?.[index];
            const hasErrors = locationErrors ? Boolean(locationErrors.gstin) : false;
            return hasValidGstin && hasValidState && !hasErrors;
        });
    };
    const deleteLocation = (index: number) => {
        const newLocations = [...formik.values.newLocations];
        newLocations.splice(index, 1);
        formik.setFieldValue('newLocations', newLocations);
        if (index == 0 && newLocations.length < 1) {
            setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS);
        }
    };

    const handleBack = () => {
        setCurrentSectionTo(LOCATION_STATES.ADD_LOCATION_NO)
        formik.setTouched({});
    };

    const handleDiaglogBox = (gstin: string) => {
         setGstinModalDialogOpen(true);
        setCurrentGstin(gstin);
    }
    useEffect(()=>{
           setIsFormValidate(!isFormValid())
    },[formik.values.newLocations, deleteLocation])
    return (
        <div className=' grid gap-8 '>
           {formik.values.locations.length > 0 && <div className={`${classes.text} justify-self-end mt-8 `}>No. of Business Location selected previously : {formik.values.locations.length.toString().padStart(2, '0')}</div>} 
            <div className={`${classes.container} p-6 grid gap-6 rounded-lg `}>
                {formik.values.newLocations?.map((location: any, index: number) => (
                    <div key={index} className='flex gap-6 items-end '>
                        <div>
                            <ButtonV2
                                variant="contained"
                                label={String(index + 1)}
                                onClick={() => { }}
                                size='medium'
                            />
                        </div>
                        <div className='grid gap-y-2 w-1/3'>
                            <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                label="Gst"
                                placeholder="Enter GST"
                                size='small'
                                fullWidth
                                {...formik.getFieldProps(`newLocations.${index}.gstin`)}
                                error={
                                    formik.touched.newLocations?.[index]?.gstin &&
                                    Boolean(
                                        formik.errors.newLocations &&
                                        Array.isArray(formik.errors.newLocations) &&
                                        (formik.errors.newLocations as FormikErrors<IGstnState>[])[index]?.gstin
                                    )
                                }
                                helperText={
                                    formik.touched.newLocations?.[index]?.gstin &&
                                    Array.isArray(formik.errors.newLocations) &&
                                    (formik.errors.newLocations as FormikErrors<IGstnState>[])[index]?.gstin
                                }
                                onChange={(event) => validateGstin(event.target.value)(index)()}
                            />
                        </div>
                        <div className='grid gap-y-2 w-1/3'>

                            <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                size='small'
                                label="Location"
                                placeholder="State will be added"
                                {...formik.getFieldProps(`newLocations.${index}.state`)}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <ButtonV2
                                className={`${classes.button} font-medium text-lg`}
                                variant="secondaryContained"
                                label="View Details"
                                onClick={() => handleDiaglogBox(formik.values.newLocations?.[index]?.gstin)}
                                size='medium'
                                disabled={!formik.values.newLocations?.[index]?.state}
                            />
                        </div>
                        <div className='mb-2'>
                            <img src={deleteIcon} alt="deleteicon" onClick={() => { deleteLocation(index) }} />
                        </div>
                    </div>
                ))}
               { gstinModalDialogOpen && <GntnDetailModalTemplate dialogOpen={gstinModalDialogOpen} setDialogOpen={setGstinModalDialogOpen} gstin={currentGstin} />}
            </div>
            <div className='flex items-end gap-2 justify-self-end'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
                <ButtonV2
                    className={`${classes.button}`}
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleBack}

                />
                <ButtonV2
                    className={`${classes.button}`}
                    variant="contained"
                    label="Save"
                    onClick={onSubmit}
                    disabled={isFormValidate}
                />
            </div>
        </div>
    )
};
export default AddNewVendorLocationTemplate;
