import React, { useEffect, useMemo, useState } from 'react'
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';
import { IMaterialFilterData } from '../GenericSearchDialog/GenericSerachDialog.template';
import { PRODUCT_SECTION, initialPaginationValues } from '../../../utils/constant';
import ProductCodeSearchV2Template from '../GenericSearchDialog/ProductCodeSearchV2.template';
import VendorSearchTemplate from '../GenericSearchDialog/VendorSearch.template';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { IVendorProductRequest, useSearchService } from '../../../services/useSearchService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import EmptyStateCard from '../../molecules/EmptyStateCard/EmptyStateCard';
import { capitalizeFirstLetter, formatDate, getProfileDisplayData, snakeCaseToTitleCase } from '../../../utils/helper';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import TextFieldV2 from '../../atoms/TextFieldV2';

interface VendorProduct {
  vendorProductId: number;
  vendorId: number;
  productId: number;
  productType: string;
  warehouseId: number;
  location: string;
  minimumOrderQuantity: number;
  updatedDate: string | null;
  productCode: string;
  description: string;
  brandName: string;
  vendorName: string;
  contactNumber: string;
  email: string;
  vendorType: string | null;
  paymentType: string | null;
  price: number | null;
  availableQuantity: number;
}

const useStyles = createUseStyles((theme: any) => ({
  backgroundColor: {
    backgroundColor: theme.palette.button.primaryDark
  },
  title: {
    color: theme.palette.textV2.primary.primary950
  },
  border: {
    border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
  }
}));

const VendorComparisonTemplate: React.FC = () => {

  const classes = useStyles();
  const searchService = useSearchService();
  const { showSnackbar } = useSnackbar();

  const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
  const [vendorFilterData, setVendorFilterData] = useState<IMaterialFilterData>({});
  const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
  const [vendorProducts, setVendorProducts] = useState<VendorProduct[]>([]);
  const [selectedUpcs, setSelectedUpcsTo] = useState<IMultiSelectOption[]>([]);
  const [selectedVendors, setSelectedVendorsTo] = useState<IMultiSelectOption[]>([]);
  const [searchText, setSearchTextTo] = useState<string>('');

  const handleUpcFilterClear = () => {
    setUpcFilterData({});
  }

  const handleVendorTypeFilterClear = () => {
    setVendorFilterData({})
  }

  const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      currentPage: newPage
    }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      pageSize: newRowsPerPage
    }));
  };

  const handleReset = () => {
    setUpcFilterData({});
    setVendorFilterData({});
    setSelectedUpcsTo([]);
    setSelectedVendorsTo([]);
    setVendorProducts([]);
  }

  const handleSearch = async () => {
    if ((searchText.length === 0) && (!vendorFilterData?.vendors || vendorFilterData.vendors.length === 0) && (!upcFilterData?.productIds || upcFilterData.productIds.length === 0)) {
      showSnackbar('error', 'Please select at least one filter option.');
      return;
    }
    let params = {
      page: pagination.currentPage,
      size: pagination.pageSize
    }
    const payload: IVendorProductRequest = {
      vendorIds: vendorFilterData?.vendors?.map(el => el.value as number) ?? null,
      productIds: upcFilterData?.productIds ? upcFilterData?.productIds : null,
      productType: "STANDARD",
      searchText: searchText
    };

    try {
      const res = await searchService.getVendorProducts(params, payload);
      if (
        res?.status === HTTP_STATUS.OK
      ) {
        const { totalElements, totalPages } = res?.data?.data;
        setVendorProducts(res.data.data.content);
        setPaginationTo({
          ...pagination,
          totalPages: totalPages,
          totalRecords: totalElements
        });
      } else {
        throw new Error("Vendor fetch failed");
      }
    } catch (error) {
      showSnackbar('error', "Vendor fetch failed")
    }
  }

  const schema = {
    id: 'template-list',
    pagination: {
      total: pagination?.totalRecords,
      currentPage: pagination?.currentPage,
      isVisible: true,
      limit: pagination?.pageSize,
      handleChangePage: handlePaginationChange,
      handleChangeRowsPerPage: handleRowsPerPageChange
    },
    columns: [
      { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
      { label: "Product Code", key: "productCode", type: "string" as ColumnType, props: {} },
      { label: "Description", key: "description", type: "string" as ColumnType, props: {} },
      { label: "Vendor Name+Code", key: "vendorName", type: "string" as ColumnType, props: {} },
      { label: "Contact No.", key: "contactNo", type: "string" as ColumnType, props: {} },
      { label: "Email ID", key: "emailId", type: "string" as ColumnType, props: {} },
      { label: "Brand", key: "brand", type: "string" as ColumnType, props: {} },
      { label: "Vendor Type", key: "vendorType", type: "string" as ColumnType, props: {} },
      { label: "Location", key: "location", type: "string" as ColumnType, props: {} },
      { label: "Payment Terms", key: "paymentTerms", type: "string" as ColumnType, props: {} },
      { label: "Price", key: "price", type: "string" as ColumnType, props: {} },
      { label: "Updated On", key: "updatedOn", type: "string" as ColumnType, props: {} },
      { label: "Available Qty", key: "availableQty", type: "string" as ColumnType, props: {} },
      { label: "Min Order Qty", key: "minOrderQty", type: "string" as ColumnType, props: {} },
      { label: "Available Offers", key: "availableOffers", type: "string" as ColumnType, props: {} },
    ]
  }

  const records = useMemo(() => {
    return vendorProducts.map((vendorProduct: any, index: number) => ([
      pagination.currentPage * pagination.pageSize + index + 1,
      getProfileDisplayData(vendorProduct.vendorProductId),
      getProfileDisplayData(vendorProduct.productCode),
      getProfileDisplayData(vendorProduct.description),
      `${getProfileDisplayData(vendorProduct.vendorName)} ${getProfileDisplayData(vendorProduct.vendorId)}`,
      getProfileDisplayData(vendorProduct.contactNumber),
      getProfileDisplayData(vendorProduct.email),
      getProfileDisplayData(vendorProduct.brandName),
      getProfileDisplayData(snakeCaseToTitleCase(vendorProduct.vendorType || '')),
      getProfileDisplayData(capitalizeFirstLetter(vendorProduct.location)),
      getProfileDisplayData(vendorProduct.paymentType),
      getProfileDisplayData(vendorProduct.price)?.toString(),
      vendorProduct.updatedDate ? formatDate(new Date(vendorProduct.updatedDate).toLocaleDateString()) : 'No Data',
      getProfileDisplayData(vendorProduct.availableQuantity)?.toString(),
      getProfileDisplayData(vendorProduct.minimumOrderQuantity)?.toString(),
      'No Data'
    ]));
  }, [vendorProducts]);

  const handleSearchTextChange = (event: any) => {
    setSearchTextTo(event.target.value)
  }

  useEffect(() => {
    if (searchText.length > 0)
      handleSearch();
  }, [searchText])

  useEffect(() => {
    if (vendorProducts.length > 0)
      handleSearch();
  }, [pagination?.currentPage, pagination?.pageSize])

  return (
    <div className='grid gap-y-4'>
      <div className={`text-2xl ${classes.title} font-medium mb-4`}>Vendor Comparison Report</div>
      <div className={`border rounded-xl p-4 ${classes.border}`}>
        <div className='flex gap-x-6'>
          <div className='w-full'>
            <div className='flex gap-2 w-full items-end'>
              <ProductCodeSearchV2Template
                materialFilters={upcFilterData}
                sectionType={PRODUCT_SECTION.BRAND_UPC}
                setFilterData={setUpcFilterData}
                onFilterSubmit={() => { }}
                handleClose={() => { }}
                handleFilterClear={handleUpcFilterClear}
                selectedUpcs={selectedUpcs}
                setSelectedUpcsTo={setSelectedUpcsTo}
              />
            </div>
          </div>
          <div className='w-full'>
            <div className='flex gap-2 w-full items-end'>
              <VendorSearchTemplate
                materialFilters={vendorFilterData}
                sectionType={PRODUCT_SECTION.BRAND_UPC}
                setFilterData={setVendorFilterData}
                onFilterSubmit={() => { }}
                handleClose={() => { }}
                handleFilterClear={handleVendorTypeFilterClear}
                selectedVendors={selectedVendors}
                setSelectedVendorsTo={setSelectedVendorsTo}
              />
            </div>
          </div>
        </div>
        <div className='grid gap-y-3'>
          <div className='w-full flex justify-end mt-3 gap-3'>
            <ButtonV2 variant='tertiaryContained' label='Reset' onClick={handleReset} />
            <ButtonV2 variant='secondaryContained' label='Search' onClick={handleSearch} />
          </div>
          <div className='flex justify-end'>
            <TextFieldV2
              fullWidth
              placeholder="Search..."
              onChange={(event) => handleSearchTextChange(event)}
            />
          </div>
        </div>
      </div>
      {vendorProducts.length === 0 ? (<EmptyStateCard message="No Information Found" />) :
        (<div className='rounded-xl overflow-x-scroll w-full relative'>
          <TableV2 schema={schema} records={records} />
        </div>)}
    </div>
  )
}

export default VendorComparisonTemplate