import React, { useEffect, useMemo, useState } from 'react'
import UpcMpcSearchDialogModal, { IMaterialFilterData } from './GenericSerachDialog.template';
import { createUseStyles } from 'react-jss';
import FilterChip from '../../molecules/FilterChip/FilterChip';
import { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';
import ButtonV2 from '../../atoms/ButtonV2';
import { GENERIC_FILTER_SECTIONS } from '../../../utils/constant';
import HoverButton from '../../atoms/HoverButton';
import filterIcon from '../../../assets/icons/outlinedFilter.svg'
import { HTTP_STATUS } from '../../../utils/types';
import { useSearchService } from '../../../services/useSearchService';
import { useSnackbar } from '../../../hooks/useSnackBar';


interface VendorSearchTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
    selectedVendors: IMultiSelectOption[];
    setSelectedVendorsTo: (selectedVendor: IMultiSelectOption[]) => void;
}

export interface IVendorByFilter {
    id: number,
    name: string,
    location: string
}

export interface IVendorFilterRequest {
    page?: number;
    size?: number;
    sort?: string;
    name?: string;
    location?: string;
    vendorType?: string;
}

const useStyles = createUseStyles((theme: any) => ({
}));


const VendorSearchTemplate: React.FC<VendorSearchTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear, selectedVendors, setSelectedVendorsTo }) => {

    const searchService = useSearchService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [vendors, setVendors] = useState<IVendorByFilter[]>([]);

    const getVendors = async (inputValue: string) => {
        let params: IVendorFilterRequest = {
            page: 0,
            size: 10
        }
        const payload = {
            location: [],
            vendorType: [],
            name: inputValue
        }
        try {
            const res = await searchService.getVendorsByFilter(params, payload);
            if (res?.status === HTTP_STATUS.OK) {
                setVendors(res.data.data.content);
            } else {
                throw new Error("Vendors fetch failed");
            }
        } catch (error) {
            showSnackbar('error', "Vendors fetch failed")
        }
    }

    const vendorOptions: IMultiSelectOption[] = useMemo(() => {
        const options = vendors?.map((vendor: IVendorByFilter) => ({ label: vendor.name, value: vendor.id }))
        return [...options, ...selectedVendors ?? []].filter((item, index, self) =>
            index === self.findIndex((t) => t.value === item.value)
        );
    }, [vendors])

    const handleClearClick = () => {
        setSelectedVendorsTo([]);
        setFilterData({
            ...materialFilters,
            vendors: []
        })
    };

    const clearAllData = () => {
        const obj = { ...materialFilters }
        delete obj.vendors
        setFilterData(obj);
    }

    const updateSelectedVendors = (selected: any) => {
        setSelectedVendorsTo(selected);
        setFilterData({
            ...materialFilters,
            vendors: selected
        })
    };

    useEffect(() => {
        getVendors('')
    }, []);

    return (
        <div className={`grid w-full`}>
            {SnackBarComponent}
            <div className={`grid gap-y-2 w-full`}>
                <div className="flex items-end gap-x-2 w-full">
                    <FilterChip
                        options={vendorOptions}
                        label="Search Vendor"
                        value={selectedVendors ?? []}
                        onchange={updateSelectedVendors}
                        placeholder="Select Vendor Type"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={handleClearClick}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={getVendors}
                        optionToShow={1}
                        className="w-full"
                    />
                    <UpcMpcSearchDialogModal
                        sections={[GENERIC_FILTER_SECTIONS.VENDOR_FILTER]}
                        productType=''
                        filterData={materialFilters}
                        setFilterData={setFilterData}
                        handleFilterClear={handleFilterClear}
                        onFilterSubmit={onFilterSubmit}
                        triggerButton={
                            <HoverButton variant="tertiaryContained"
                                iconButton={<img src={filterIcon} alt="Filter Icon" />}
                                label=""
                                size='small'
                                buttonWithImg={true}
                                hoverButton={!!materialFilters?.productIds?.length}
                                hoverButtonLabel={materialFilters?.productIds?.length}
                                handleCloseIcon={clearAllData}
                                className=''
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default VendorSearchTemplate