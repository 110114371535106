import { request } from ".";
import { IMasterCustomerWithUpcParams, IUserDetailsParams } from "../components/templates/MasterUPCListing/MasterCustomerWithUpc.template";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { IUpcApplicationsBodyValues } from "../components/templates/MasterUPCListing/AddMasterUpc";

export const UPC_URLS = {
  GET_MASTER_PRODUCT_UPC: "/product-upcs",
  GET_UPC_RELATED_BRANDS_BY_ID: "/product-upc-brands",
  ADD_BRAND_TO_UPC: "/product-upc/:id",
  GET_PRODUCT_UPC_BY_ID: "/product-upc/:id",
  GET_BRAND_UPC_BY_ID: "/brand-upc/:brandUpcId",
  DELETE_BRAND_FROM_UPC: "/product-upcs/:id",
  GET_CUSTOMER_UPC_DETAILS: "/customer-upc/:id",
  DELETE_CUSTOMER_UPC_MAPPING: "/customer-upc/:id/:customerId",
  GET_ALL_USERS: "/users",
  GET_RELATED_BRANDS_BY_ID: "/product-upcs/:id/related-brands",
  GET_INVENTORIES_BY_ID: "/product-upcs/:id/inventories",
  GET_OTHERS_BY_ID: "/product-upcs/:id/related-products/secondary",
  ADD_NEW_CUSTOMER_UPC: "/customer-upc/:id/:customerId",
  ADD_UPC_APPLICATION: "/product-upc/:id",
  GET_CUSTOMER_EXCEL_UPC_DETAILS: "/customer-upc/:productUpcId/downloadExcel",
  GET_RELATED_BRANDS: "/product-upcs/:productUpcId/related-brands/generateExcel",
  GET_PROCESS_PRIME_DETAILS: "/product-upcs/:productUpcId/related-products/secondary/generateExcel",
  GET_INVENTORY_DETAILS: "/product-upcs/:productUpcId/inventories/generateExcel"
};

export interface IMasterProductUPCRequest {
  status?: string | null;
  page?: number;
  size?: number;
  sort?: string;
  search?: string;
}
export interface IUPCRelatedBrandsRequest {
  status?: string | null;
  page?: number;
  size?: number;
  sort?: string;
  upcId: number;
  search: string;
}

export interface IMasterUpcDetailsRequestBody {
  upcTypes: string;
}
export interface IUpdateBrandMasterUpcCreateRequest {
  masterUpcId: number;
}

export interface IRelatedBrandsRequest {
  page?: number;
  size?: number;
  search?: string;
  brandIds?: string;
  sort?: string;
}

export interface IMasterInventoriesRequest {
  page?: number;
  size?: number;
  search?: string;
  warehouseId?: string;
}

export interface IMasterOthersRequest {
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
}

export interface IUpcApplicationsParams {
  categoryId: number | undefined;
}

export interface IMasterOthersBody {
  skuIds?: number[];
}

export interface IRequestBodyValues {
  upcIds: (string | number)[] | null;
  brandIds: (string | number)[] | null;
}

export const useMasterUpcService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;
  const BASE_URL_V1 = process.env.REACT_APP_API_V1_URL;
  const getMasterProductUPCs = (params: IMasterProductUPCRequest, data: IRequestBodyValues) => {
    return request(API_METHOD.POST, UPC_URLS.GET_MASTER_PRODUCT_UPC, authenticatedUser, data, { params }, null, BASE_URL_V2);
  };

  const getUpcRelatedBrand = (params: IUPCRelatedBrandsRequest, data: IRequestBodyValues) => {
    return request(API_METHOD.POST, UPC_URLS.GET_UPC_RELATED_BRANDS_BY_ID, authenticatedUser, data, { params });
  };

  const addBrandToMasterUPC = (id: number, params: IUpdateBrandMasterUpcCreateRequest) => {
    return request(API_METHOD.POST, replaceUrlParams(UPC_URLS.ADD_BRAND_TO_UPC, { id }), authenticatedUser, null, { params }, null, BASE_URL_V2);
  };

  const getProductUpcById = (id: number, params: IMasterUpcDetailsRequestBody) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_PRODUCT_UPC_BY_ID, { id }), authenticatedUser, null, { params });
  };

  const getRelatedBrandsById = (id: number, params: IRelatedBrandsRequest) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_RELATED_BRANDS_BY_ID, { id }), authenticatedUser, null, { params });
  };

  const getInventoriesById = (id: number, params: IMasterInventoriesRequest) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_INVENTORIES_BY_ID, { id }), authenticatedUser, null, { params });
  };

  const getOthersById = (id: number, params: IMasterOthersRequest,) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_OTHERS_BY_ID, { id }), authenticatedUser, null, { params });
  };

  const addSecondaryPrime = (id: number, data: IMasterOthersBody) => {
    return request(API_METHOD.POST, replaceUrlParams(UPC_URLS.GET_OTHERS_BY_ID, { id }), authenticatedUser, data);
  };

  const deleteBrandFromMasterUpc = (id: number, params: IUpdateBrandMasterUpcCreateRequest) => {
    return request(API_METHOD.PUT, replaceUrlParams(UPC_URLS.DELETE_BRAND_FROM_UPC, { id }), authenticatedUser, null, { params }, null, BASE_URL_V2);
  };

  const getCustomerUpcDetails = (id: number, params: IMasterCustomerWithUpcParams) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_CUSTOMER_UPC_DETAILS, { id }), authenticatedUser, null, { params }, null, BASE_URL_V2);
  };

  const deleteCustomerUpcMapping = (id: number, customerId: number) => {
    return request(API_METHOD.DELETE, replaceUrlParams(UPC_URLS.DELETE_CUSTOMER_UPC_MAPPING, { id, customerId }), authenticatedUser, null, { params: {} }, null, BASE_URL_V2);
  };

  const getAllUsersDetails = (params: IUserDetailsParams) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_ALL_USERS, {}), authenticatedUser, null, { params }, null, BASE_URL_V1);
  };

  const addNewCustomerWithUpc = (id: number, customerId: string | undefined) => {
    return request(API_METHOD.POST, replaceUrlParams(UPC_URLS.ADD_NEW_CUSTOMER_UPC, { id, customerId }), authenticatedUser, null, { params: {} }, null, BASE_URL_V2);
  };

  const addMasterUpcApplication = (id: number, params: IUpcApplicationsParams, data: IUpcApplicationsBodyValues) => {
    return request(API_METHOD.PUT, replaceUrlParams(UPC_URLS.ADD_UPC_APPLICATION, { id }), authenticatedUser, data, { params }, null, BASE_URL_V2);
  };

  const getProductUpcByIdV2 = (id: number, params: IMasterUpcDetailsRequestBody) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_PRODUCT_UPC_BY_ID, { id }), authenticatedUser, null, { params }, null, BASE_URL_V2);
  };

  const getBrandUpcByIdV2 = (brandUpcId: number, params: IMasterUpcDetailsRequestBody) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_BRAND_UPC_BY_ID, { brandUpcId }), authenticatedUser, null, { params }, null, BASE_URL_V2);
  };

  const getExcelDetailsForCustomerWithUpc = (productUpcId: number, params: IMasterCustomerWithUpcParams) => {
   return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_CUSTOMER_EXCEL_UPC_DETAILS, { productUpcId }), authenticatedUser, null, { params }, null, BASE_URL_V2);
  }
  const getExcelDetailsForRelatedBrands = (productUpcId: number, params: IRelatedBrandsRequest) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_RELATED_BRANDS, { productUpcId }), authenticatedUser, null, { params});
   }

   const getProcessPrimeExcelDetails = (productUpcId: number, params: IMasterOthersRequest) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_PROCESS_PRIME_DETAILS, { productUpcId }), authenticatedUser, null, { params});
   }

   const getInventoryExcelDetails = (productUpcId: number, params: IMasterInventoriesRequest) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_INVENTORY_DETAILS, { productUpcId }), authenticatedUser, null, { params});
   }


  return {
    getMasterProductUPCs,
    getUpcRelatedBrand,
    addBrandToMasterUPC,
    getProductUpcById,
    deleteBrandFromMasterUpc,
    getRelatedBrandsById,
    getInventoriesById,
    getOthersById,
    addSecondaryPrime,
    getCustomerUpcDetails,
    deleteCustomerUpcMapping,
    getAllUsersDetails,
    addNewCustomerWithUpc,
    addMasterUpcApplication,
    getProductUpcByIdV2,
    getBrandUpcByIdV2,
    getExcelDetailsForCustomerWithUpc,
    getExcelDetailsForRelatedBrands,
    getProcessPrimeExcelDetails,
    getInventoryExcelDetails
  };
};
