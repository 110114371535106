import VendorComparisonTemplate from '../../templates/ReportAndAnalytics/VendorComparison.template';

export interface VendorComparisonFilter {
  productIds: number[];
  vendorIds: number[];
}

const VendorComparisonPage = () => {

  return (
    <VendorComparisonTemplate />
  )
}

export default VendorComparisonPage