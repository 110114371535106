import React, { useEffect, useMemo, useState } from "react";
import ButtonV2 from "../../atoms/ButtonV2";
import { useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { HTTP_STATUS } from "../../../utils/types";
import UpcMpcSearchDialogModal, { IMaterialFilterData } from "./GenericSerachDialog.template";
import { IProductUPCRequest, useUpcService } from "../../../services/useUpcService";
import { CLASS_TYPE } from "../../../utils/types";
import { GENERIC_FILTER_SECTIONS, PRODUCT_SECTION } from "../../../utils/constant";
import { useSecondaryInventoryService } from "../../../services/useSecondaryInventoryService";
import FilterChip from "../../molecules/FilterChip/FilterChip";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput2";
import HoverButton from "../../atoms/HoverButton";
import filterIcon from '../../../assets/icons/outlinedFilter.svg'

interface ProductCodeSearchV2TemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
    selectedUpcs: IMultiSelectOption[];
    setSelectedUpcsTo: (selectedUpc: IMultiSelectOption[]) => void;
}

interface IUpcsByFilter {
    id: number;
    upcCode: string
    productId: number;
    productCategory: string;
    classType: string;
    shape: string;
    status: string;
    attributes: string;
    createdAt: string;
    updatedAt: string;
}

interface IBrandUpcsByFilter {
    upcCode: string;
    status: string;
    brand: string;
    upcId: number;
}

interface ISecondaryInventory {
    id: number;
    skuCode: string;
    name: string;
    warehouseName: string;
    quantity: number;
    createdAt: string;
    updatedAt: string;
}

const ProductCodeSearchV2Template: React.FC<ProductCodeSearchV2TemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear, selectedUpcs, setSelectedUpcsTo }) => {

    const upcService = useUpcService();
    const secondaryInventoryService = useSecondaryInventoryService();
    const customerGroupService = useCustomerGroupMasterService();

    const [upcs, setUpcsTo] = useState<IUpcsByFilter[]>([]);
    const [brandUpcs, setBrandUpcsTo] = useState<IBrandUpcsByFilter[]>([]);
    const [secondaryInventories, setSecondaryInventoriesTo] = useState<ISecondaryInventory[]>([]);

    const getProductDetails = async (inputValue?: string) => {
        try {
            if (sectionType === PRODUCT_SECTION.MASTER_UPC) {
                let params: IProductUPCRequest = {
                    page: 0,
                    size: 10,
                    status: '',
                    upcCode: inputValue ?? '',
                    sort: "createdAt,desc",
                    classType: CLASS_TYPE.STANDARD
                }
                const upcsResponse = await upcService.getProductUPCs(params);
                if (upcsResponse?.status === HTTP_STATUS.OK && upcsResponse?.data?.data?.content?.length) {
                    setUpcsTo(upcsResponse?.data?.data?.content);
                } else {
                    throw new Error("Upcs fetch failed");
                }
            }
            else {
                if (sectionType === PRODUCT_SECTION.BRAND_UPC) {
                    let params = {
                        page: 0,
                        size: 10,
                        sort: "createdAt,desc",
                        upcCode: inputValue ?? ''
                    }
                    const brandUpcsResponse = await customerGroupService.getUpcByMaterialFilter(params, { classes: [CLASS_TYPE.STANDARD] });
                    if (brandUpcsResponse?.status === HTTP_STATUS.OK && brandUpcsResponse?.data?.data) {
                        setBrandUpcsTo(brandUpcsResponse?.data?.data?.content);
                    } else {
                        throw new Error("Brand upcs fetch failed");
                    }
                }
            }
        } catch (error) {
            setBrandUpcsTo([]);
            setUpcsTo([]);
        }
    };

    const getSecondaryInventories = async (inputValue?: string) => {
        let params = {
            skuCode: inputValue ?? '',
            page: 0,
            size: 10,
            sort: "createdAt,desc",
        }
        const secondaryInventoriesResponse = await secondaryInventoryService.getAllSecondaryInventories({}, params);
        if (secondaryInventoriesResponse?.status === HTTP_STATUS.OK && secondaryInventoriesResponse?.data?.data?.content?.length) {
            setSecondaryInventoriesTo(secondaryInventoriesResponse?.data?.data?.content);
        } else {
            throw new Error("Secondary inventories fetch failed");
        }
    }

    const updateSelectedUpc = (key: string) => (option: IMultiSelectOption[] | null) => {
        if (option) {
            const optionsSet = new Set(option?.map(option => JSON.stringify(option)));
            const uniqueOptionsArray = Array.from(optionsSet)?.map(optionStr => JSON.parse(optionStr));
            setSelectedUpcsTo(uniqueOptionsArray);
            setFilterData({
                ...materialFilters,
                productIds: uniqueOptionsArray?.map(el => el.value as number) ?? null,
            })
        }
    };

    const upcOptions = useMemo(() => {
        return upcs?.map((upc: IUpcsByFilter) => ({ label: upc.upcCode, value: upc.id })) ?? []
    }, [upcs])

    const brandUpcOptions = useMemo(() => {
        return brandUpcs?.map((upc: IBrandUpcsByFilter) => ({ label: upc.upcCode, value: upc.upcId })) ?? []
    }, [brandUpcs])

    const skuOptions = useMemo(() => {
        return secondaryInventories?.map((sku: ISecondaryInventory) => ({ label: sku.skuCode, value: sku.id })) ?? []
    }, [secondaryInventories]);

    const handleClearClick = (key: string) => {
        setFilterData({
            ...materialFilters,
            productIds: []
        });
        setSelectedUpcsTo([]);
    };

    const clearAllData = () => {
        setFilterData({});
    }

    useEffect(() => {
        if ((sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC)) {
            getProductDetails();
        } else if (sectionType === PRODUCT_SECTION.SECONDARY) {
            getSecondaryInventories();
        }
    }, []);

    return (
        <div className={`grid w-full`}>
            <div className={`grid gap-y-2 w-full`}>
                {(sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC) && <div className="flex items-end gap-x-2 w-full">
                    <FilterChip
                        options={sectionType === PRODUCT_SECTION.MASTER_UPC ? upcOptions : brandUpcOptions}
                        label="Search Product"
                        value={selectedUpcs ?? []}
                        onchange={updateSelectedUpc('productCode')}
                        placeholder="Search by UPC"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('productCode')}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={getProductDetails}
                        optionToShow={1}
                        className="w-full"
                    />
                    <UpcMpcSearchDialogModal
                        sections={[GENERIC_FILTER_SECTIONS.MATERIAL_FILTER]}
                        productType={PRODUCT_SECTION.BRAND_UPC}
                        filterData={materialFilters}
                        setFilterData={setFilterData}
                        handleFilterClear={handleFilterClear}
                        onFilterSubmit={onFilterSubmit}
                        triggerButton={
                            <HoverButton variant="tertiaryContained"
                                iconButton={<img src={filterIcon} alt="Filter Icon" />}
                                label=""
                                size='small'
                                buttonWithImg={true}
                                hoverButton={!!materialFilters?.productIds?.length}
                                hoverButtonLabel={materialFilters?.productIds?.length}
                                handleCloseIcon={clearAllData}
                                className=''
                            />
                        }
                    />
                </div>
                }
                {sectionType === PRODUCT_SECTION.SECONDARY && <div className="grid gap-y-2 w-2/3">
                    <FilterChip
                        options={skuOptions}
                        label=""
                        value={selectedUpcs ?? []}
                        onchange={updateSelectedUpc('productCode')}
                        placeholder="Search by SKU"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('productCode')}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={getSecondaryInventories}
                        optionToShow={1}
                    />
                </div>
                }
            </div>
        </div>
    );
};

export default ProductCodeSearchV2Template;