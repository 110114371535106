import React, { useEffect, useMemo, useState } from 'react'
import { IMaterialFilterData } from './GenericSerachDialog.template';
import { createUseStyles } from 'react-jss';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import FilterChip from '../../molecules/FilterChip/FilterChip';
import { PRODUCT_SECTION, SORT_OPTION, STATUS_OPTION, VENDOR_TYPES, cityOptions } from '../../../utils/constant';
import ButtonV2 from '../../atoms/ButtonV2';
import { useFormik } from 'formik';
import { IVendorByFilter, IVendorFilterRequest } from './VendorSearch.template';
import { useSearchService } from '../../../services/useSearchService';
import { HTTP_STATUS, option } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface VendorFilterTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const VendorFilterTemplate: React.FC<VendorFilterTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear }) => {
    const classes = useStyles();
    const [vendorFilter, setVendorFilter] = useState<{vendorLocation: IMultiSelectOption[], vendorType: IMultiSelectOption[]}>({
        vendorLocation: materialFilters.vendorLocation ?? [],
        vendorType: materialFilters.vendorType ?? []
    });

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const updateSelectedVendorsDetails = (key: string) => (option: IMultiSelectOption[] | null) => {
        setFilterData({
            ...materialFilters,
            [key]: option
        })
        setVendorFilter(prev => ({
            ...prev, [key] : option
        }))
    }
    useEffect(()=>{
        setVendorFilter({
            vendorLocation: materialFilters.vendorLocation ?? [],
            vendorType: materialFilters.vendorType ?? []
        })
    },[materialFilters])

    const handleClearClick = (key: string) => {
        setFilterData({
            ...materialFilters,
            vendors: []
        })
    };

    

    return (
        <div className="grid gap-y-4">
            <div className={` p-4 m-4 grid gap-y-5 rounded-lg`}>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-lg font-medium my-auto`}>Search By Vendor Details</div>
                </div>

                <div className="grid gap-x-6 h-fit grid-cols-2 w-full">
                    <FilterChip
                        options={cityOptions.map(option => ({value: option.id, label: option.label}))}
                        label="Location"
                        value={vendorFilter.vendorLocation ?? []}
                        onchange={updateSelectedVendorsDetails('vendorLocation')}
                        placeholder="Search by name"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('vendorLocation')}
                        isSearchable={false}
                        optionToShow={1}
                        className="w-full"
                    />
                    <FilterChip
                        options={VENDOR_TYPES.map(type => type)}
                        label="Vendor Type"
                        value={vendorFilter.vendorType ?? []}
                        onchange={updateSelectedVendorsDetails('vendorType')}
                        placeholder="Search by name"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('vendorType')}
                        isSearchable={false}
                        optionToShow={1}
                        className="w-full"
                    />
                </div>
            </div>
        </div >
    )
}

export default VendorFilterTemplate