import Drawer from '@mui/material/Drawer';
import React, { useEffect, useMemo, useState } from "react";
import MaterialFilterTemplate from './MaterialFilterSection.template';
import { option } from '../../../utils/types';
import { Button } from '@mui/material';
import ButtonV2 from '../../atoms/ButtonV2';
import ProductCodeSearchFormTemplate from './ProductCodeSearch.template';
import OtherFilterFormTemplate from './OtherFilterSection.template';
import ProductSelectionFormTemplate from './ProductSelection.template';
import { filter } from 'lodash';
import { GENERIC_FILTER_SECTIONS } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import { boolean } from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import VendorFilterTemplate from './VendorFilter.template';
import VendorFilterSelectionTemplate from './VendorFilterSelection.template';

export interface ProductSearchDialogTemplateProps {
  sections: string[];
  productType: string;
  filterData: IMaterialFilterData;
  setFilterData: (filterData: any) => void;
  handleFilterClear: () => void;
  onFilterSubmit: () => void;
  triggerButton: React.ReactNode;
}
export interface IData {
  [key: string]: IMultiSelectOption[] | [] | number[] | IMultiSelectOption | null | undefined | { [key: string]: ICatalogueAttributes; };
}
export interface IMaterialFilterData extends IData {
  productCode?: IMultiSelectOption[] | null;
  superCategory?: IMultiSelectOption[] | null,
  mainCategory?: IMultiSelectOption[] | null,
  productCategory?: IMultiSelectOption[] | null,
  manufacturer?: IMultiSelectOption[] | null,
  brand?: IMultiSelectOption[] | null,
  standard?: IMultiSelectOption[] | null,
  grade?: IMultiSelectOption[] | null,
  shape?: IMultiSelectOption[] | null,
  classes?: IMultiSelectOption[] | null,
  customer?: IMultiSelectOption[] | null,
  status?: IMultiSelectOption[] | null,
  sort?: IMultiSelectOption[] | null,
  attributes?: { [key: string]: ICatalogueAttributes; } | null,
  productIds?: number[] | null,
  vendors?: IMultiSelectOption[] | null,
  vendorLocation?: IMultiSelectOption[] | null,
  vendorType?:IMultiSelectOption[] | null
}

// export interface IMaterialFilterData {
//   productCode?: AdvanceFilterSchema | null;
//   superCategory?: AdvanceFilterSchema | null,
//   mainCategory?: AdvanceFilterSchema | null,
//   productCategory?: AdvanceFilterSchema | null,
//   manufacturer?: AdvanceFilterSchema | null,
//   brand?: AdvanceFilterSchema | null,
//   standard?: AdvanceFilterSchema | null,
//   grade?: AdvanceFilterSchema | null,
//   shape?: AdvanceFilterSchema | null,
//   classes?: AdvanceFilterSchema | null,
//   customer?: AdvanceFilterSchema | null,
//   status?: AdvanceFilterSchema | null,
//   sort?: AdvanceFilterSchema | null,
//   attributes?: AdvanceFilterSchema | null,
//   productIds?: AdvanceFilterSchema | null,
// }

// export interface AdvanceFilterSchema {
//   singleSelect: boolean,
//   isSearchable: boolean,
//   isDisabled: boolean,
//   options: IData | null,
// }

export interface ICatalogueAttributes {
  minValue: string | null;
  maxValue: string | null;
  values: string[] | null;
}

const useStyles = createUseStyles((theme: any) => ({
  container: {
    border: `1px solid #EBEFFA`
  },
  filterContainer: {
    borderTop: `1px solid #EBEFFA`
  },
  filterHeading: {
    color: theme.palette.textV2.tertiary.tertiary900,
  },
  body: {
    border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
  },
  border: {
    borderBottom: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
  },
}));

export const ACTIVE_COMPONENT_SECTIONS = {
  FILTERS: "FILTERS",
  PRODUCT_LIST: "PRODUCT_LIST",
  VENDOR_LIST: "VENDOR_LIST"
}


const UpcMpcSearchDialogModal: React.FC<ProductSearchDialogTemplateProps> = ({ sections, productType, filterData, setFilterData, handleFilterClear, onFilterSubmit, triggerButton }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeComponent, setActiveComponent] = React.useState(ACTIVE_COMPONENT_SECTIONS.FILTERS);

  const handleClose = () => {
    setActiveComponent(ACTIVE_COMPONENT_SECTIONS.FILTERS)
    setOpen(prev => !prev);
  }

  const clearAllSelection = () => {
    activeComponent === ACTIVE_COMPONENT_SECTIONS.VENDOR_LIST ?
      setFilterData({
        ...filterData,
        vendors: []
      }) : setFilterData({
        ...filterData,
        productIds: []
      });
  }

  const activeContent = useMemo(() => {
    if (activeComponent === ACTIVE_COMPONENT_SECTIONS.FILTERS)
      return (
        <>
          <div className={`${classes.container} p-x-6 border m-x-6 rounded-lg relative`}>
            <div className="flex m-4 justify-between">
              <div className={`${classes.filterHeading} text-2xl font-medium my-auto `}>Search and Select Product</div>
              <div className={`cursor-pointer`} onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
            <div className={`${classes.border}`}></div>
            <div className={`${classes.body} rounded-lg m-4`}>
              {sections.includes(GENERIC_FILTER_SECTIONS.UPC) && <ProductCodeSearchFormTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
              />}
              {sections.includes(GENERIC_FILTER_SECTIONS.MATERIAL_FILTER) && <MaterialFilterTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
              />}
              {sections.includes(GENERIC_FILTER_SECTIONS.VENDOR_FILTER) && <VendorFilterTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
              />}
              {sections.includes(GENERIC_FILTER_SECTIONS.OTHER) && <OtherFilterFormTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
              />}

            </div>
            <div className="flex justify-between gap-y-3 gap-x-3 p-3 sticky bottom-0 right-0 z-100000 w-full bg-white">
              <div className="flex gap-x-5">
                <ButtonV2 variant="secondaryText" size="large" label={"Clear All"} onClick={handleFilterClear} />
              </div>
              <div className='flex gap-y-3 gap-x-3'>
                <div className="flex gap-x-5">
                  <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={() => { handleClose() }} />
                </div>
                <div className="flex gap-x-5">
                  <ButtonV2 variant="primaryContained" size="large" label={"Search"} onClick={() => { setActiveComponent(sections.includes(GENERIC_FILTER_SECTIONS.VENDOR_FILTER) ? ACTIVE_COMPONENT_SECTIONS.VENDOR_LIST : ACTIVE_COMPONENT_SECTIONS.PRODUCT_LIST) }} />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    else if (activeComponent === ACTIVE_COMPONENT_SECTIONS.PRODUCT_LIST)
      return (
        <>
          <div className={`p-x-6 m-x-6 rounded-lg relative`}>
            <div className={`p-6`}>
              <ProductSelectionFormTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
              />
            </div>
            <div className="flex justify-between gap-y-3 gap-x-3 p-3 sticky bottom-0 right-0 z-100000 w-full bg-white">
              <div className="flex gap-x-5">
                <ButtonV2 variant="secondaryText" size="large" label={"Clear All Selection"} onClick={clearAllSelection} />
              </div>
              <div className='flex gap-y-3 gap-x-3'>
                <div className="flex gap-x-5">
                  <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={() => { setActiveComponent(ACTIVE_COMPONENT_SECTIONS.FILTERS) }} />
                </div>
                <div className="flex gap-x-5">
                  <ButtonV2
                    variant="primaryContained"
                    size="large"
                    label={`+Add(${filterData?.productIds?.length || 0})`} 
                    onClick={() => {
                      onFilterSubmit();
                      handleClose();
                    }}
                  />
                </div></div>
            </div>
          </div>
        </>
      );
    else if (activeComponent === ACTIVE_COMPONENT_SECTIONS.VENDOR_LIST)
      return (
        <>
          <div className={`p-x-6 m-x-6 rounded-lg relative`}>
            <div className={`p-6`}>
              <VendorFilterSelectionTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
              />
            </div>
            <div className="flex justify-between gap-y-3 gap-x-3 p-3 sticky bottom-0 right-0 z-100000 w-full bg-white">
              <div className="flex gap-x-5">
                <ButtonV2 variant="secondaryText" size="large" label={"Clear All Selection"} onClick={clearAllSelection} />
              </div>
              <div className='flex gap-y-3 gap-x-3'>
                <div className="flex gap-x-5">
                  <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={() => { setActiveComponent(ACTIVE_COMPONENT_SECTIONS.FILTERS) }} />
                </div>
                <div className="flex gap-x-5">
                  <ButtonV2
                    variant="primaryContained"
                    size="large"
                    label={`+Add(${(activeComponent === ACTIVE_COMPONENT_SECTIONS.VENDOR_LIST ? filterData.vendors : filterData.productIds)?.length || 0})`} 
                    onClick={() => {
                      onFilterSubmit();
                      handleClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }, [filterData, activeComponent, sections]);

  return (
    <div>
      {React.cloneElement(triggerButton as React.ReactElement, { onClick: handleClose })}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => { }}
        sx={{
          width: '43%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '60%',
            boxSizing: 'border-box',
          },
        }}
      >
        {activeContent}
      </Drawer>
    </div>
  );
}

export default UpcMpcSearchDialogModal;