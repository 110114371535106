import React from 'react'
import { IInstantQuotation } from '../../pages/ManageQuotation/QuoteCreate.page';
import { FormikErrors, FormikProps } from 'formik';
import TextFieldV2 from '../../atoms/TextFieldV2';

export interface IPointOfContactProps {
    formik: FormikProps<IInstantQuotation>;
    isEditable: boolean;
}
interface IInstantQuotationPOC {
  name: string;
  number: string;
  emailId: string;
}

export const PointOfContacTemplate: React.FC<IPointOfContactProps> = ({formik, isEditable}) => {

    if (formik.values.pocInformation.length === 0) {
        formik.setFieldValue('pocInformation', [{ name: '', number: '', emailId: '' }, { name: '', number: '', emailId: '' }]);
      }
  return (
    <div className="grid gap-6">
    {formik.values.pocInformation.map((poc, index) => (
      <div key={index} className="w-full">
        <span>{index === 0 ? 'Primary Contact' : 'Secondary Contact'}</span>
        <div className="flex py-3 gap-3">
          <div className="w-1/3">
            <TextFieldV2
              {...formik.getFieldProps(`pocInformation[${index}].name`)}
              className="text-base font-medium"
              type="text"
              label="Person Name"
              placeholder="Enter Data"
              fullWidth
              required
              disabled={!isEditable || (formik.values.pocInformation[0].name === "" && index === 1)}
              error={formik.touched?.pocInformation?.[index]?.name &&
                Boolean((formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.name)
              }
              helperText={formik.touched?.pocInformation?.[index]?.name &&
                 Boolean((formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.name) ? (formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.name : ''}
            />
          </div>
          <div className="w-1/3">
            <TextFieldV2
              {...formik.getFieldProps(`pocInformation[${index}].number`)}
              className="text-base font-medium"
              type="text"
              label="Phone Number"
              placeholder="Enter Data"
              fullWidth
              required
              disabled={!isEditable || (formik.values.pocInformation[0].name === "" && index === 1)} 
              error={formik.touched?.pocInformation?.[index]?.number &&
                Boolean((formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.number)
              }
              helperText={formik.touched?.pocInformation?.[index]?.number &&
                 Boolean((formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.number) ? (formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.number : ''}

            />
          </div>
          <div className="w-1/3">
            <TextFieldV2
              {...formik.getFieldProps(`pocInformation[${index}].emailId`)}
              className="text-base font-medium"
              type="text"
              label="Email ID"
              placeholder="Enter Data"
              fullWidth
              required
              disabled={!isEditable || (formik.values.pocInformation[0].name === "" && index === 1)}
              error={formik.touched?.pocInformation?.[index]?.emailId &&
                Boolean((formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.emailId)
              }
              helperText={formik.touched?.pocInformation?.[index]?.emailId &&
                 Boolean((formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.emailId) ? (formik.errors?.pocInformation as FormikErrors<IInstantQuotationPOC>[])?.[index]?.emailId : ''}

            />
          </div>
        </div>
      </div>
    ))}
  </div>
  )
}
