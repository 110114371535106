import { IVendorFilterRequest } from '../components/templates/GenericSearchDialog/VendorSearch.template'
import { API_METHOD } from '../utils/constant';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { request } from '.';

interface IProductFilter {
    productId: number,
    productType: string
}

export interface IVendorProductRequest {
    vendorIds: number[] | null,
    productIds: number[] | null,
    productType: string;
    searchText: string;
}

export const SEARCH_URLS = {
    VENDOR_SEARCH: "/search/vendor",
    VENDOR_PRODUCT_SEARCH: "/search/vendor-discovery"
}

export const useSearchService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getVendorsByFilter = (params: IVendorFilterRequest, data: any) => {
        return request(API_METHOD.POST, SEARCH_URLS.VENDOR_SEARCH, authenticatedUser, data, { params });
    }

    const getVendorProducts = (params: any, data: IVendorProductRequest) => {
        return request(API_METHOD.POST, SEARCH_URLS.VENDOR_PRODUCT_SEARCH, authenticatedUser, data, { params });
    }

    return { getVendorsByFilter, getVendorProducts }
}