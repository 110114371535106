import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {
    Select as MuiMultiSelect,
    MenuItem,
    SelectProps as MuiMultiSelectProps,
    InputAdornment,
    MenuProps
} from "@mui/material";
import TextFieldV2 from '../../atoms/TextFieldV2';
import ButtonV2 from '../../atoms/ButtonV2';

const useStyles = createUseStyles((theme: any) => ({
    select: {
        "& .MuiInputBase-input": {
            fontWeight: 400,
            fontSize: "16px",
        },

        border: "1px solid",
        borderColor: theme.palette.border.secondaryDark,
        borderRadius: '8px !important',
        height: "56px",
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
    },
    placeholder: {
        color: `${theme.palette.textV2.tertiary.tertiary400} !important`
    },
    checkedInputColor: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    primaryHighlighter: {
        background: theme.palette.textV2.primary.primary400,
        color: 'white'
    },
    searchContainer: {
        padding: '8px 16px',
    },
    clearButton: {
        margin: '8px',
    },
    saveButton: {
        margin: '8px',
    },
}));

export interface IMultiSelectOption {
    label: string;
    value: string | number;
}

interface MultiSelectInputProps extends Omit<MuiMultiSelectProps, "label"> {
    options: IMultiSelectOption[];
    label: string;
    helperText?: string;
    value: IMultiSelectOption[];
    onchange: (selected: IMultiSelectOption[]) => void;
    placeholder?: string;
    textFieldLabel?: string;
    textFieldPlaceholder?: string;
    TextFieldComponent?: React.ComponentType<any>;
    ButtonComponent?: React.ComponentType<any> | null;
    SaveButton?: React.ComponentType<any> | null;
    buttonLabel?: string;
    saveButtonLabel?: string;
    buttonOnClick?: () => void;
    searchTerm?: string;
    setSearchTerm?: (value: string) => void;
    saveButtonOnClick?: () => void;
    optionToShow?: number;
    isSingleSelect?: boolean;
    isSearchable? : boolean;
    maxHeight?: string;
}

const FilterChip: React.FC<MultiSelectInputProps> = ({ options, label, helperText, value, onchange, placeholder, textFieldLabel, textFieldPlaceholder,TextFieldComponent = TextFieldV2, ButtonComponent = ButtonV2, SaveButton, buttonLabel, saveButtonLabel,buttonOnClick, searchTerm, setSearchTerm, saveButtonOnClick, optionToShow = 2, isSingleSelect = false ,isSearchable=true, maxHeight = '50%', ...props}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleChange = (event: any) => {
        const selectedValues = event.target.value as (string | number)[];
        if (isSingleSelect){
            if (selectedValues.length > 0) {
                const selectedOptions = options.filter(option => option.value === selectedValues[selectedValues.length - 1]);
                onchange(selectedOptions);
            }
        } else {
            const selectedOptions = options.filter(option => selectedValues.includes(option.value));
            onchange(selectedOptions);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setSearchTerm) {
            setSearchTerm(event.target.value);
        }
    };

    const handleClose = () => setOpen(false);

    const renderValue = (selected: any) => {
        const selectedValues = selected as (string | number)[];
        const selectedOptions = options.filter((option) => selectedValues.includes(option.value));
        if (selectedOptions.length === 0) {
            return <span className={`${classes.placeholder}`}>{placeholder}</span>;
        } else if (selectedOptions.length === 1) {
            return selectedOptions[0].label;
        } else if (selectedOptions.length === optionToShow) {
            return selectedOptions.map((option) => option.label).join(', ');
        } else {
            const firstTwo = selectedOptions.slice(0, optionToShow).map((option) => option.label).join(', ');
            return (
                <div>{firstTwo}{' '}<span className={`py-0.5 px-1 text-sm rounded-xl ${classes.primaryHighlighter}`}>+{selectedOptions.length - optionToShow}</span></div>
            );
        }
    };

    const menuProps: Partial<MenuProps> = {
        PaperProps: {
            style: {
                maxHeight,
            },
        },
    };

    return (
        <div className={`flex flex-col gap-2 flex-1`}>
            {label && (
                <div className={classes.label}>
                    {label}
                </div>
            )}
            <MuiMultiSelect
                id={`multi-select-${label}`}
                disableUnderline={true}
                displayEmpty
                {...props}
                MenuProps={menuProps}
                value={value?.map(option => option?.value)}
                multiple
                size='medium'
                className={`${classes.select}`}
                renderValue={(selected) => {
                    return (value?.length || (selected as (number | string)[])?.length) ? renderValue(selected) : <span className={`${classes.placeholder} !font-normal `}>{placeholder}</span>;
                }}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onChange={handleChange}
            >
                {isSearchable && <div className={`${classes.searchContainer}`}>
                    <TextFieldV2
                        label={textFieldLabel}
                        size="small"
                        placeholder={textFieldPlaceholder}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={e => e.stopPropagation()}
                    />
                </div>}
                {options.map((option: any) => (
                    <MenuItem key={option.value} value={option.value} onKeyDown={e => e.stopPropagation()}>
                        <Checkbox className={classes.checkedInputColor} checked={value.some(selectedOption => selectedOption.value === option.value)} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
                <div className='flex justify-between'>
                    {ButtonComponent && (
                        <div className={classes.clearButton}>
                            <ButtonComponent variant='secondaryText'  onClick={() => { buttonOnClick?.(); handleClose(); }} label={buttonLabel} />
                        </div>
                    )}
                   {SaveButton && (
                        <div className={`${classes.saveButton}`}>
                            <SaveButton variant='primaryText' onClick={() => {saveButtonOnClick?. (); handleClose();}} label={saveButtonLabel} />
                        </div>
                    )}
                </div>
            </MuiMultiSelect>
        </div>
    )
}

export default FilterChip;
