import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import AddMasterUpc from "./AddMasterUpc";
import ButtonV2 from "../../atoms/ButtonV2";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import TextField from "../../atoms/TextField";
import { theme } from "../../../utils/theme";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import { InputAdornment, SelectChangeEvent } from "@mui/material";
import { BULK_UPLOAD_TEMPLATE_TYPE, GENERIC_FILTER_SECTIONS, initialPaginationValues, PRODUCT_SECTION } from "../../../utils/constant";
import { IMasterOthersRequest, useMasterUpcService } from "../../../services/useMasterUpcService";
import TextFieldV2 from "../../atoms/TextFieldV2";
import moment from "moment";
import UpcMpcSearchDialogModal, { IMaterialFilterData } from "../GenericSearchDialog/GenericSerachDialog.template";
import search from '../../../assets/images/search.svg';
import downloadExcelIcon from '../../../assets/icons/downloadExcelIcon.svg';



const useStyles = createUseStyles({
    headingText: {
        color: theme.palette.buttonV2.secondaryContained.text.primaryDark
    },
    container: {
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
    },
});

export interface IUpcOtherFilter {
    search: string;
}

const initialUpcOthersFiltersValues: IUpcOtherFilter = {
    search: ""
}

export interface IOthers {
    id: number;
    skuCode: string;
    createdAt: string;
    orders: number;
}

interface IMasterOther {
    id: number;
}

const MasterOtherTemplate: React.FC<IMasterOther> = ({ id }) => {
    const classes = useStyles();
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [othersFilters, setOthersFiltersTo] = useState<IUpcOtherFilter>(initialUpcOthersFiltersValues);
    const [others, setOthers] = useState<IOthers[]>([]);
    const masterUpcService = useMasterUpcService();
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
    const [filterSection, setFilterSections] = useState<string[]>([]);

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    useEffect(() => {
        setFilterSections([GENERIC_FILTER_SECTIONS.UPC, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER, GENERIC_FILTER_SECTIONS.OTHER]);
    }, [])

    const fetchUpc = async (page: number, size: number) => {
        if (id) {
            try {
                let params: IMasterOthersRequest = {
                    search: othersFilters?.search ?? null,
                    page: page,
                    size: size,
                    sort: "createdAt,desc"
                }
                const upcResponse = await masterUpcService.getOthersById(id, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    setOthers(upcResponse?.data?.data?.content);
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: upcResponse.data.data.totalElements,
                        totalPages: upcResponse.data.data.totalPages,
                    }));

                }
                else {
                    throw new Error("Related Brands fetch failed");
                }
            } catch (error) {
                console.error("error", `Related Brands fetch failed`)
            }
        }
    }

    useEffect(() => {
        fetchUpc(pagination.currentPage, pagination.pageSize)
    }, [pagination.currentPage, pagination.pageSize, id, othersFilters]);


    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setOthersFiltersTo({ ...othersFilters, [name]: value ?? "" });
    }


    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };
    const onFilterSubmit = async () => {
        try {
            const upcResponse = await masterUpcService.addSecondaryPrime(id, { skuIds: upcFilterData.productIds ?? [] });
            if (upcResponse?.status === HTTP_STATUS.OK) {
                fetchUpc(pagination.currentPage, pagination.pageSize)
            }
            else {
                throw new Error("Added Secondary Prime");
            }
        } catch (error) {
            console.error("error", `Added Secondary Prime`)
        }

        fetchUpc(pagination.currentPage, pagination.pageSize)
    }

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Processed Prime", key: "skuCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Date of Addition", key: "createdAt", type: "string" as ColumnType, props: { className: '' } },
            { label: "Orders", key: "orders", type: "number" as ColumnType, props: { className: '' } },
        ],
    };
    const records = others.map((other: IOthers, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        other.skuCode,
        moment(other.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        other.orders,
    ]);

    const getProcessPrimeExcelDetails = async () => {
        if (id) {
            try {
                let params: IMasterOthersRequest = {
                    search: othersFilters?.search ?? null
                }
                const processPrimeExcelResponse = await masterUpcService.getProcessPrimeExcelDetails(id, params);
                const byteCharacters = atob(processPrimeExcelResponse?.data?.data?.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_OCTET_STREAM });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = processPrimeExcelResponse?.data?.data?.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error fetching customer upc details data:', error);
            }
        }
    };


    return (
        <>
            <div className="flex flex-col gap-6">
                <div className={`${classes.container} p-4 rounded-lg mt-5`}>
                    <div className="flex items-center justify-between mb-6">
                        <h2 className={`${classes.headingText} font-semibold text-lg`}>Associated Processed Prime</h2>
                        <div className="flex gap-x-4 items-center">
                            <TextFieldV2
                                className="w-72"
                                type="text"
                                placeholder='Search By Name..'
                                label=" "
                                variant="outlined"
                                name="search"
                                value={othersFilters.search}
                                onChange={handleFiltersChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                                }}
                            />
                            <ButtonV2 variant={"tertiaryContained"} label={"Excel"} iconButton={<img src={downloadExcelIcon} alt="Excel Icon" />} buttonWithImg = {true} onClick={getProcessPrimeExcelDetails} ></ButtonV2>
                            <UpcMpcSearchDialogModal
                                sections={filterSection}
                                productType={PRODUCT_SECTION.SECONDARY}
                                filterData={upcFilterData}
                                setFilterData={setUpcFilterData}
                                handleFilterClear={handleFilterClear}
                                onFilterSubmit={onFilterSubmit}
                                triggerButton={
                                    <ButtonV2 variant={"primaryContained"} label={"+Add Process Prime"}></ButtonV2>
                                }
                            />
                        </div>
                    </div>
                    <TableV2 schema={schema} records={records} />
                </div>
                <AddMasterUpc id={id} />
            </div>
        </>
    );
};
export default MasterOtherTemplate;